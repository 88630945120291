import { acceptHMRUpdate } from 'pinia'
import type { ZipCodeDetails } from '~/server/helpers/zipResolver'

interface Visitor {
  location: ZipCodeDetails | null
  b: boolean
}

export const useVisitorStore = defineStore('Visitor', () => {
  const visitorData = useCookie<Visitor | null>('visitor-data')
  if (!visitorData.value) {
    visitorData.value = {
      location: null,
      b: false,
    }
  }

  const currentRoute = useRouter().currentRoute
  const zipFromUrl = computed(() => currentRoute.value.query.zip || '')

  /// location and isBot section
  if (useNuxtApp().ssrContext) {
    const ssrContext = useNuxtApp().ssrContext
    if (ssrContext) {
      const ssrLocation = ssrContext.event?.context?.resolvedLocation as ZipCodeDetails || null

      visitorData.value.location = ssrLocation ?? visitorData.value.location
      visitorData.value.b = !!ssrContext.event?.context?.b
    }
  }

  // consider changing to activeZipCode?
  const zipCode = computed(() => (zipFromUrl.value === visitorData.value?.location?.zip && visitorData.value?.location?.zip) || '')

  const location = computed(() => toRaw(visitorData.value?.location))

  const isBot = computed(() => visitorData.value?.b)

  return {
    visitorData,
    location,
    zipCode,
    isBot,
  }
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useVisitorStore, import.meta.hot))
